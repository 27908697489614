const Enf = [

  {
    "OffersNo": 1514158,
    "DescriptionOffre": "Avon Girls Rule : Laits pour le Corps 250ml ",
    "category": "Enfants ",
    "Column4": "Enfants",
    "PrixRegulier": 30,
    "PrixResultatOffre": 17
   },
   {
    "OffersNo": 1514157,
    "DescriptionOffre": "Avon Girls Rule : Brume pour le Corps 100ml",
    "category": "Enfants ",
    "Column4": "Enfants",
    "PrixRegulier": 24,
    "PrixResultatOffre": 14
   },
   {
    "OffersNo": 1514156,
    "DescriptionOffre": "Avon Girls Rule : Gel Douche 250ml ",
    "category": "Enfants ",
    "Column4": "Enfants",
    "PrixRegulier": 24,
    "PrixResultatOffre": 14
   },
   {
    "OffersNo": 1577000,
    "DescriptionOffre": "Naturals Kids : Spray cheveux Mangue 200ml",
    "category": "Enfants ",
    "Column4": "Enfants",
    "PrixRegulier": 28,
    "PrixResultatOffre": 16
   },
   {
    "OffersNo": 1393600,
    "DescriptionOffre": "Naturals Kids : Shampooing et après Shampooing Pomme 250ml",
    "category": "Enfants ",
    "Column4": "Enfants",
    "PrixRegulier": 28,
    "PrixResultatOffre": 16
   },
 //  {
  //   "OffersNo": 328100,
  //   "DescriptionOffre": "Avon Naturals Kids: Gel Douche et Bain Mousse Lavande 250ml",
  //   "category": "Enfants ",
  //   "Column4": "Enfants",
  //   "PrixRegulier": 28,
  //   "PrixResultatOffre": 16
  //  }

];

export default Enf;
